import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { formatDate } from "../utils";
import { SlidingDiv } from "../components/sliding";

const useStyles = makeStyles(theme => createStyles({
  root: {
    margin: "0 0 60px 0",
    justifyContent: "center",
    border: "1px solid #e9e9e9",
    borderRadius: "4px",
    "&:hover": {
      "& $title": {
        color: "#858585",
      },
    },
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    height: 0,
    paddingTop: "75%", // 4:3 Aspect Ratio
  },
  coverImage: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: 'center',
    margin: 0,
  },
  content: {
    width: "100%",
    padding: "20px",
    backgroundColor: "#fff",
    color: "#222222",
    
  },
  title: {
    fontSize: "16px",
    marginBottom: "14px",
    fontWeight: 700,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "&:hover": {
      color: "#858585",
    },
  },
  date: {
    fontSize: "12px",
    color: "#b4b4b4",
  },
}));

interface PrCardProps {
  title: string;
  createdAt: string;
  image: string;
}

const PrCard = ({ title, createdAt, image }: PrCardProps) => {
  const classes = useStyles();
  const publishedDate = new Date(createdAt);

  return (
    <SlidingDiv className={classes.root}>
      <div className={classes.imageContainer}>
        <div className={classes.coverImage} style={{ backgroundImage: `url(${image})`}} />
      </div>
      <div className={classes.content}>
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
        <Typography component="p" className={classes.date}>
          {formatDate(publishedDate)}
        </Typography>
      </div>
    </SlidingDiv>
  );
};

export default PrCard;
